//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 *
 * FooterIndex Component
 *
 * @author emartinez <efren.martinez@marciano.com.mx>
 * @copyright 2019, Marciano Studio S.C.
 */

export default {
  name: 'footer-index',
  methods: {
    fullYear() {
      let date = new Date()
      return date.getFullYear()
    }
  }
}
