//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 *
 * SiteCard Component
 *
 * @author Fernando Torres
 * @copyright 2020, Marciano Studio S.C.
 */
export default {
  name: 'site-card',
  props: {
    index: {
      type: Number,
      required: true,
      default: null
    },
    title: {
      type: String,
      required: true,
      default: null
    },
    subtitle: {
      type: String,
      required: true,
      default: null
    },
    background: {
      type: String,
      required: true,
      default: null
    },
    image: {
      type: String,
      required: true,
      default: null
    },
    link: {
      type: [Object, String],
      required: true,
      default: null
    },
    clip: {
      type: String,
      required: true,
      default: null
    }
  }
}
