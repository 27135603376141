import { render, staticRenderFns } from "./site-card.vue?vue&type=template&id=0ba3420c&scoped=true&"
import script from "./site-card.vue?vue&type=script&lang=js&"
export * from "./site-card.vue?vue&type=script&lang=js&"
import style0 from "./site-card.vue?vue&type=style&index=0&id=0ba3420c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba3420c",
  null
  
)

export default component.exports
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QImg from 'quasar/src/components/img/QImg.js'
qInstall(component, 'components', {QImg})
