import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e68fe528&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=e68fe528&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e68fe528",
  null
  
)

export default component.exports
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QCarousel from 'quasar/src/components/carousel/QCarousel.js'
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js'
qInstall(component, 'components', {QCarousel,QCarouselSlide})
